<template>
    <el-form ref="form" :model="model" :show-message="false" label-width="auto" label-position="top" v-loading="loading">
    <div class="row justify-content-center">
        <div class="col" v-loading="uploading">
            <el-form-item prop="file_path" :rules="[{required:true}]">
                <el-upload
                    class="text-center"
                    name="excel"
                    drag
                    action="/backend/api/uploadexcel"
                    :with-credentials="true"
                    :show-file-list="false"
                    :on-success="handleUploadSuccess"
                    :on-error="handelUploadError"
                    :on-progress="progressing"
                    :before-upload="beforeUpload"
                    >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text ls-2">拖拽文件到这里或<i class="text-success">浏览本地文件</i></div>
                    <div class="el-upload__tip ls-2" slot="tip" style="line-height:1.5">
                        <span class="ls-1" >请上传后缀名为「xlsx」的文件，文件大小不能超过2MB，</span>
                        <span class="ls-2"><strong>填报时不得修改表格格式，否则将导致导入错误。</strong></span><br/>
                        <b><a :href="sampleLinks[dataType]" class="text-success" style="line-height:1.3">[ 下载表格模版 ]</a></b>
                    </div>
                </el-upload>
            </el-form-item>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col">
            <div class="el-table" style="max-height: 260px; overflow: scroll; border:1px solid #ececec;">
            <table>
                <thead>
                    <tr>
                        <td v-for="col in excelData.headers" :key="col" class="pl-2">{{ col }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row,rowIdx) in excelData.rows" :key="rowIdx">
                        <td v-for="(col,colIdx) in row" :key="rowIdx+'-'+colIdx" class="pl-2">{{ col }}</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
    
    <div class="row mt-5">
        <div class="col">
            <h5 class="text-center">选择数据所属日期范围</h5>
            <div class="d-flex justify-content-center">
                <el-form-item prop="year" :rules="[{required:true}]" class="mr-2">
                    <el-select v-model="model.year" placeholder="年份">
                        <el-option v-for="y in years" :key="y" :label="y" :value="y"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="date_range" :rules="[{required:true}]">
                    <el-date-picker
                        v-model="model.date_range"
                        type="daterange"
                        range-separator="-"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        :disabled="!model.year"
                        start-placeholder="起始日期"
                        end-placeholder="截止日期">
                    </el-date-picker>
                </el-form-item>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <el-button type="success" class="mt-4 ls-3" @click="submit" :disabled="!ready || !model.year || !model.date_range" v-loading="loading">导入</el-button>
            <el-button type="secondary" class="mt-4 ls-3" @click="cancel" :disabled="loading">取消</el-button>
        </div>
    </div>
    </el-form>
</template>

<script>
/* eslint-disable */
export default {
    props: {
        dataType: {
            type: Number,
            description: "统计表类型",
            default: null
        },
        entry: {
            type: Object,
            defualt: null
        }
    },
    data() {
      return {
        sampleLinks: [
            '/samples/purchases-import-sample.xlsx',
            '/samples/sales-import-sample.xlsx'
        ],
        years: [],
        pickerOptions: {
            disabledDate: (d) => {
                return d.getFullYear() != this.model.year
            }
        },
        model: {
            data_type: this.dataType,
            year: '',
            date_range: [],
            start_date: '',
            end_date: '',
            file_path: ''
        },
        excelData: { headers: [], rows: []},
        ready: false,
        loading: false,
        uploading: false
      }
    },
    watch: {
        'model.date_range' (v) {
            this.model.start_date = v[0]
            this.model.end_date = v[1]
        }
    },
    methods: {
        beforeUpload(file) {
            if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                this.$notifyErr('请上传后缀名为.xlsx的Excel文件')
                return false
            }
            const isLt4M = file.size / 1024 / 1024 < 4
            if (!isLt4M) {
                this.$notifyErr('文件大小不能超过4M')
                return false
            }
            return true
        },
        progressing () {
            this.uploading = true
        },
        handleUploadSuccess(res, file) {
            this.uploading = false
            if(res.errcode != 0) {
                this.$notifyErr('文件上传失败', res.errmsg)
            }
            else {
                console.log(this.dataType)
                var dataTypeName = this.dataType === 0 ? '采购' : '销售'
                if (!res.data.headers.find(x => x.indexOf(dataTypeName) >= 0)) {
                    this.$notifyErr(`您上传的表格可能不是《${dataTypeName}》统计表！`);
                    return
                }
                this.model.file_path = res.url;
                this.excelData = res.data;
                this.ready = true;
            }
        },
        handelUploadError() {
            this.uploading = false
            this.$notifyErr('上传失败');
        },
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(!this.model.file_path) { alert('请上传表格'); return false; }
                    this.save();
                } else {
                    alert('请填写完整信息');
                    return false;
                }
            });
        },
        save() {
            this.loading = true;
            this.axios({
                method: 'post',
                url: 'datainputs',
                data: this.model
            }).then((res) => {
                this.loading = false;
                if (res.data.errcode == 0) {
                    this.$emit("saved")
                } else {
                    this.$notifyErr('导入失败', res.data.errmsg)
                }
            }).catch((err) => {
                this.$notifyErr('导入失败', 'Failed: ' + err.message)
                this.loading = false
            })
        },
        cancel() {
            this.$emit('cancelled')
        }
    },
    mounted() {
        var curYear = new Date().getFullYear()
        for(var y = curYear - 3; y <= curYear + 1; y++) {
            this.years.push(y)
        }
        this.model.year = curYear
        if (this.entry) {
            Object.assign(this.model, this.entry)
        }
    }
};
</script>
<style>
    .el-select, .el-autocomplete {
        width: 100%;
    }
    label.required::before {
        content: '*';
        color: red;
        position: absolute;
        top: 0;
        left: 6px;
        font-weight: 300;
        font-size: smaller;
    }
    .el-upload, .el-upload-dragger {
        width: 100%;
        height: 150px;
    }
    .el-upload-dragger .el-icon-upload {
        margin: 25px 0 16px;
    }
    .card .table td, .card .table th, .card .el-table td, .card .el-table th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    div.el-table tbody td, div.el-table thead th {
        padding: 0.3rem;
    }
</style>